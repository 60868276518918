import { API_URL } from '../config';
// hooks/useProducts.js
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const fetchProducts = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('No token found');
        return;
      }

      const response = await axios.get(`${API_URL}/api/products`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(response.data); // Fetch and set all products
    } catch (error) {
      setErrorMessage('Error fetching products');
    }
  }, []);

  const fetchTopProducts = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setErrorMessage('No token found');
        return;
      }

      const response = await axios.get(`${API_URL}/api/products/top`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTopProducts(response.data);
    } catch (error) {
      setErrorMessage('Error fetching top products');
    }
  }, []);

  useEffect(() => {
    fetchProducts();
    fetchTopProducts();
  }, [fetchProducts, fetchTopProducts]);

  return { products, topProducts, errorMessage };
};

export default useProducts;
