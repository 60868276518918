import { API_URL } from '../config';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/api/login`, { email, password });
      const { token } = response.data;
      localStorage.setItem('token', token);
      setToken(token);
      return true;
    } catch (error) {
      console.error("Error logging in:", error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  const checkAuth = useCallback(async () => {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${API_URL}/api/validate-token`, {
        headers: {
          'Authorization': `Bearer ${storedToken}`
        }
      });
      if (response.status === 200) {
        setToken(storedToken);
      } else {
        localStorage.removeItem('token');
        setToken(null);
      }
    } catch (error) {
      console.error("Error validating token:", error);
      localStorage.removeItem('token');
      setToken(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return {
    token,
    isAuthenticated: !!token,
    loading,
    login,
    logout,
  };
};

export default useAuth;
