import { API_URL } from '../config';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Select, { components } from 'react-select';
import ReactSlider from 'react-slider';
import editIcon from '../assetes/img/edit.png';
import priceTracker from '../assetes/img/pricetracker.svg';
import PopupAlert from '../components/PopupAlert'; // Import the PopupAlert component
import './products.css';
import useActiveSheet from '../hooks/useActiveSheet'; // Import useActiveSheet hook

const Products = () => {
  const [products, setProducts] = useState([]);
  const [topProducts, setTopProducts] = useState([]);
  const [mostFrequentProducts, setMostFrequentProducts] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState([]);
  const [selectedSim, setSelectedSim] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [highlightedProduct, setHighlightedProduct] = useState(null);
  const [profitRange, setProfitRange] = useState([0, 200000]);
  const [scanningStatus, setScanningStatus] = useState('Checking...');
  const [nextScanTime, setNextScanTime] = useState(null);
  const [countdown, setCountdown] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [editInputVisible, setEditInputVisible] = useState(null);
  const [newProductPrice, setNewProductPrice] = useState({});
  const [trackingList, setTrackingList] = useState([]);
  const productRefs = useRef({});
  const [scraperStatuses, setScraperStatuses] = useState([]);
  const [activeScrapers, setActiveScrapers] = useState([]);
  const [scraperCountdowns, setScraperCountdowns] = useState({});

  // Use the custom hook for managing active sheet
  const { activeSheet, error, activateSheet } = useActiveSheet();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        // Fetch products sorted by created_at in descending order (newest first)
        const response = await axios.get(`${API_URL}/api/products?sort=-created_at`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProducts(response.data);
      } catch (error) {
        if (error.message === 'No token found') {
          setErrorMessage('Authentication required');
        } else {
          setErrorMessage('Error fetching products');
        }
        setTimeout(() => setErrorMessage(''), 3000);
      }
    };

    fetchProducts();

    const interval = setInterval(fetchProducts, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  // // Function to activate a new sheet (example with hardcoded sheetId)
  // const handleActivateSheet = (sheetId) => {
  //   activateSheet(sheetId); // Use the activateSheet function from the hook
  // };

  // useEffect(() => {
  //   const fetchScraperStatus = async () => {
  //     try {
  //       const token = localStorage.getItem('token');
  //       const response = await axios.get(`${API_URL}/api/markets`, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });
  //       const markets = response.data;

  //       const scraperStatuses = await Promise.all(
  //         markets.map(async (market) => {
  //           const statusResponse = await axios.get(`${API_URL}/api/scraper/status/${market._id}`, {
  //             headers: { Authorization: `Bearer ${token}` }
  //           });
  //           const nextRunResponse = await axios.get(`${API_URL}/api/scraper/next-run/${market._id}`, {
  //             headers: { Authorization: `Bearer ${token}` }
  //           });
  //           return {
  //             marketId: market._id,
  //             marketName: market.name,
  //             status: statusResponse.data.status,
  //             nextRunTime: nextRunResponse.data.nextRunTime
  //           };
  //         })
  //       );

  //       setScraperStatuses(scraperStatuses);
  //     } catch (error) {
  //       console.error('Error fetching scraper statuses:', error);
  //     }
  //   };

  //   fetchScraperStatus();
  //   const statusInterval = setInterval(fetchScraperStatus, 60 * 1000); // Update every minute

  //   return () => clearInterval(statusInterval);
  // }, []);

  useEffect(() => {
    if (nextScanTime) {
      const countdownInterval = setInterval(() => {
        const now = new Date().getTime();
        const distance = nextScanTime - now;

        if (distance < 0) {
          setScanningStatus('Folyamatban...');
          setNextScanTime(null);
          clearInterval(countdownInterval);
        } else {
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          setCountdown(`${minutes}m ${seconds}s`);
        }
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [nextScanTime]);

  useEffect(() => {
    if (products.length > 0) {
      calculateMostFrequentProducts(); // Always calculate most frequent products
    }

    if (activeSheet) {
      calculateTopProducts(); // Only calculate top products if activeSheet is set
    }
  }, [products, activeSheet]);

  const calculateProfit = (product, price = null) => {
    if (!activeSheet) return 0;
    const matchingProduct = activeSheet.products.find(sheetProduct =>
      sheetProduct.series === product.longest_model &&
      sheetProduct.memory === product.storage &&
      sheetProduct.condition === product.condition &&
      sheetProduct.sim === product.sim
    );
    if (matchingProduct) {
      const sellingPrice = price ? parseFloat(price) : parseFloat(matchingProduct.price);
      const buyingPrice = parseFloat(product.price);
      const profit = sellingPrice - buyingPrice;
      return profit >= 0 ? profit : 0;
    }
    return 0;
  };

  const productOptions = [...new Set(products.map(item => item.longest_model))].map(item => ({ value: item, label: item }));

  const sortStorageOptions = (a, b) => {
    const storageOrder = ['4 GB alatt', '4 GB', '8 GB', '16 GB', '32 GB', '64 GB', '128 GB', '256 GB', '512 GB', 'egyéb'];
    return storageOrder.indexOf(a) - storageOrder.indexOf(b);
  };

  const storageOptions = [...new Set(products.map(item => item.storage))]
    .sort(sortStorageOptions)
    .map(item => ({ value: item, label: item }));

  const simOptions = [...new Set(products.map(item => item.sim))].map(item => ({ value: item, label: item }));
  const platformOptions = [...new Set(products.map(item => item.platform))].map(item => ({ value: item, label: item }));
  const cityOptions = [...new Set(products.map(item => item.city))].map(item => ({ value: item, label: item }));

  const handleProductChange = selectedOptions => {
    setSelectedProduct(selectedOptions);
  };

  const handleStorageChange = selectedOptions => {
    setSelectedStorage(selectedOptions);
  };

  const handleSimChange = selectedOptions => {
    setSelectedSim(selectedOptions);
  };

  const handlePlatformChange = selectedOptions => {
    setSelectedPlatform(selectedOptions);
  };

  const handleCityChange = selectedOptions => {
    setSelectedCity(selectedOptions);
  };

  const handleProfitRangeChange = (values) => {
    setProfitRange(values);
  };

  const filterProducts = () => {
    return products.filter(product =>
      (selectedProduct.length === 0 || selectedProduct.some(option => option.value === product.longest_model)) &&
      (selectedStorage.length === 0 || selectedStorage.some(option => option.value === product.storage)) &&
      (selectedSim.length === 0 || selectedSim.some(option => option.value === product.sim)) &&
      (selectedPlatform.length === 0 || selectedPlatform.some(option => option.value === product.platform)) &&
      (selectedCity.length === 0 || selectedCity.some(option => option.value === product.city)) &&
      (profitRange[0] <= calculateProfit(product) && calculateProfit(product) <= profitRange[1])
    ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at in descending order
  };

  const filteredProducts = filterProducts();

  const calculateTopProducts = () => {
    const productsWithProfit = products.map(product => {
      const profit = calculateProfit(product);
      return { ...product, profit: profit !== null ? parseFloat(profit) : null };
    });

    const sortedProducts = productsWithProfit
      .filter(product => product.profit !== null && product.profit > 0)
      .sort((a, b) => b.profit - a.profit);

    const top5Products = sortedProducts.slice(0, 5);
    setTopProducts(top5Products);
  };

  const calculateMostFrequentProducts = () => {
    const productCount = products.reduce((acc, product) => {
      acc[product.longest_model] = (acc[product.longest_model] || 0) + 1;
      return acc;
    }, {});

    const sortedProducts = Object.keys(productCount)
      .map(key => ({ name: key, count: productCount[key] }))
      .filter(product => product.name !== 'N/A')
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);

    setMostFrequentProducts(sortedProducts);
  };

  const getProfitClass = (product) => {
    const profit = calculateProfit(product);
    return profit && parseFloat(profit) > 0 ? 'positive-profit' : '';
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#34495e',
      borderColor: '#34495e',
      color: '#ecf0f1',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#34495e',
      color: '#ecf0f1',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#2c3e50' : '#34495e',
      color: '#ecf0f1',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#2c3e50',
      color: '#ecf0f1',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#ecf0f1',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#ecf0f1',
      ':hover': {
        backgroundColor: '#2c3e50',
        color: '#ecf0f1',
      },
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    })
  };

  const Placeholder = (props) => {
    return <components.Placeholder {...props}>Keresés...</components.Placeholder>;
  };

  const scrollToProduct = (product) => {
    const productKey = `${product._id}`;
    if (productRefs.current[productKey]) {
      productRefs.current[productKey].scrollIntoView({ behavior: 'smooth', block: 'start' });
      setHighlightedProduct(productKey);
      setTimeout(() => setHighlightedProduct(null), 9000);
    }
  };

  const toggleDropdown = (productKey) => {
    if (dropdownVisible === productKey) {
      setDropdownVisible(null);
      setEditInputVisible(null);
    } else {
      setDropdownVisible(productKey);
      setEditInputVisible(null);
    }
  };

  const toggleEditInput = (productKey) => {
    if (editInputVisible === productKey) {
      setEditInputVisible(null);
    } else {
      setEditInputVisible(productKey);
    }
  };

  const handlePriceChange = (productKey, value) => {
    setNewProductPrice(prevState => ({ ...prevState, [productKey]: value }));
  };

  const handleAddProductToSheet = async (product) => {
    const activeSheetId = localStorage.getItem('activeSheetId');
    const productKey = `${product._id}`;
    const price = newProductPrice[productKey];

    if (!activeSheetId || !price) {
      setErrorMessage('Nincs új ár megadva');
      setTimeout(() => setErrorMessage(''), 3000);
      return;
    }

    const token = localStorage.getItem('token');
    const newProduct = {
      series: product.longest_model,
      memory: product.storage,
      condition: product.condition,
      sim: product.sim,
      price
    };

    try {
      const existingProduct = activeSheet.products.find(sheetProduct =>
        sheetProduct.series === newProduct.series &&
        sheetProduct.memory === newProduct.memory &&
        sheetProduct.condition === newProduct.condition &&
        sheetProduct.sim === newProduct.sim
      );

      if (existingProduct) {
        await axios.put(`${API_URL}/api/sheets/${activeSheetId}/update-product/${existingProduct._id}`, { price: newProduct.price }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      } else {
        await axios.put(`${API_URL}/api/sheets/${activeSheetId}/add-product`, { product: newProduct }, {
          headers: { Authorization: `Bearer ${token}` }
        });
      }

      const updatedProducts = products.map(p => ({
        ...p,
        profit: calculateProfit(p, price)
      }));

      setProducts(updatedProducts);

      setSuccessMessage('Termék frissítve');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      setErrorMessage('Hiba a termék frissítésekor.');
      setTimeout(() => setErrorMessage(''), 3000);
    }
  };

  const handleActiveSheetClick = () => {
    if (activeSheet) {
      window.location.href = `/models/sheet-edit/${activeSheet._id}`;
    } else {
      window.location.href = `/models`;
    }
  };

  const getSavedPrice = (product) => {
    if (!activeSheet) return '';
    const matchingProduct = activeSheet.products.find(sheetProduct =>
      sheetProduct.series === product.longest_model &&
      sheetProduct.memory === product.storage &&
      sheetProduct.condition === product.condition &&
      sheetProduct.sim === product.sim
    );
    return matchingProduct ? matchingProduct.price : '';
  };

  const handleColorChange = async (productKey, color) => {
    try {
      const product = filteredProducts.find(p => `${p._id}` === productKey);
      const token = localStorage.getItem('token');
      const response = await axios.put(`${API_URL}/api/products/${product._id}/highlight`, { highlightColor: color }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProducts(prevProducts => prevProducts.map(p => p._id === product._id ? { ...p, highlightColor: color } : p));
    } catch (error) {
      setErrorMessage('Error updating product highlight color');
      console.error('Error updating product highlight color:', error);
    }
  };

  const handleTrackProduct = (product) => {
    setTrackingList((prevList) => [...prevList, product]);
    setSuccessMessage('Product added to tracking list');
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found');
      return;
    }

    const eventSource = new EventSource(`${API_URL}/api/products/updates?token=${token}`);

    eventSource.onopen = () => {
      console.log('EventSource connection established');
    };

    eventSource.onmessage = (event) => {
      console.log('EventSource message received:', event.data);
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'productUpdate') {
          const updatedProduct = data.data;
          setProducts((prevProducts) => {
            const productIndex = prevProducts.findIndex(product => product._id === updatedProduct._id);
            if (productIndex !== -1) {
              const updatedProducts = [...prevProducts];
              updatedProducts[productIndex] = updatedProduct;
              return updatedProducts;
            } else {
              return [updatedProduct, ...prevProducts];
            }
          });
        }
      } catch (error) {
        console.error('Error processing EventSource message:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('EventSource error:', error);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    const fetchActiveScrapers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/scraper/active`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setActiveScrapers(response.data);
      } catch (error) {
        console.error('Error fetching active scrapers:', error);
      }
    };

    fetchActiveScrapers();
    const scrapersInterval = setInterval(fetchActiveScrapers, 60 * 1000); // Update every minute

    return () => clearInterval(scrapersInterval);
  }, []);

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date().getTime();
      const updatedCountdowns = {};

      activeScrapers.forEach((scraper) => {
        if (scraper.nextRunTime) {
          const nextRun = new Date(scraper.nextRunTime).getTime();
          const distance = nextRun - now;

          if (distance > 0) {
            const hours = Math.floor(distance / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            updatedCountdowns[scraper.scraperId] = `${hours}h ${minutes}m ${seconds}s`;
          } else {
            updatedCountdowns[scraper.scraperId] = 'Folyamatban...';
          }
        }
      });

      setScraperCountdowns(updatedCountdowns);
    };

    calculateCountdown();
    const countdownInterval = setInterval(calculateCountdown, 1000);

    return () => clearInterval(countdownInterval);
  }, [activeScrapers]);

  return (
    <div className="products-container">
      <PopupAlert message={successMessage} type="success" visible={!!successMessage} />
      <PopupAlert message={errorMessage} type="error" visible={!!errorMessage} />

      <div className="top-products">
        <h4 className='top-products-title'>Legnagyobb Árrés</h4>
        <ul>
          {activeSheet ? (
            topProducts.length > 0 ? (
              topProducts.map((product, index) => (
                <li key={index} className="top-product-item" onClick={() => scrollToProduct(product)}>
                  <span className="top-product-rank">{index + 1}.</span>
                  <span className="top-product-name">{product.longest_model}</span>
                  <span className="top-product-profit">{product.profit.toFixed(2)}</span>
                  <a href={product.link} target="_blank" rel="noopener noreferrer" className="top-product-link">View</a>
                </li>
              ))
            ) : (
              <li>No products with profit to display.</li>
            )
          ) : (
            <li>No Sheet Selected</li>
          )}
        </ul>
      </div>

      <div className="most-frequent-products">
        <h4 className='most-frequent-products-title'>Mennyiség A Piacon</h4>
        <ul>
          {mostFrequentProducts.map((product, index) => (
            <li key={index} className="most-frequent-product-item">
              <span className="most-frequent-product-rank">{index + 1}.</span>
              <span className="most-frequent-product-name">{product.name}</span>
              <span className="most-frequent-product-count">{product.count}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="additional-info">
        <h4 className="additional-info-title">További Információ</h4>
        <ul>
          <li className="additional-info-item">
            <span className="additional-info-label">Aktív lista:</span>
            <span className="additional-info-value" onClick={handleActiveSheetClick} style={{ cursor: 'pointer', color: '#29b8d8' }}>
              {activeSheet ? activeSheet.name : 'Létrehozás'}
            </span>
          </li>
          {activeScrapers.length > 0 && activeScrapers[0].status !== 'No active scrapers' ? (
            activeScrapers.map((scraper) => (
              <li key={scraper.scraperId} className="additional-info-item">
                <span className="additional-info-label">{scraper.marketName}:</span>
                <span className="additional-info-value">
                  {scraper.isCurrentlyRunning ? (
                    <span style={{ color: 'green' }}>Folyamatban...</span>
                  ) : scraper.willRunLater ? (
                    <span>{scraperCountdowns[scraper.scraperId] || 'Számítás...'}</span>
                  ) : (
                    'Nincs ütemezve'
                  )}
                </span>
              </li>
            ))
          ) : (
            <li className="additional-info-item">
              <span className="additional-info-label">Scraper státusz:</span>
              <span className="additional-info-value">Nincs aktív scraper</span>
            </li>
          )}
        </ul>
      </div>

      <div className="filters">
        <div className="filter-group">
          <label>Termék:</label>
          <Select
            options={productOptions}
            isMulti
            onChange={handleProductChange}
            value={selectedProduct}
            className="dropdown"
            styles={customStyles}
            components={{ Placeholder }}
          />
        </div>
        <div className="filter-group">
          <label>Tárhely:</label>
          <Select
            options={storageOptions}
            isMulti
            onChange={handleStorageChange}
            value={selectedStorage}
            className="dropdown"
            styles={customStyles}
            components={{ Placeholder }}
          />
        </div>
        <div className="filter-group">
          <label>Szolgáltató:</label>
          <Select
            options={simOptions}
            isMulti
            onChange={handleSimChange}
            value={selectedSim}
            className="dropdown"
            styles={customStyles}
            components={{ Placeholder }}
          />
        </div>
        <div className="filter-group">
          <label>Platform:</label>
          <Select
            options={platformOptions}
            isMulti
            onChange={handlePlatformChange}
            value={selectedPlatform}
            className="dropdown"
            styles={customStyles}
            components={{ Placeholder }}
          />
        </div>
        <div className="filter-group">
          <label>Város:</label>
          <Select
            options={cityOptions}
            isMulti
            onChange={handleCityChange}
            value={selectedCity}
            className="dropdown"
            styles={customStyles}
            components={{ Placeholder }}
          />
        </div>
        <div className="filter-group">
          <label>Profit Tartomány:</label>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            min={0}
            max={200000}
            value={profitRange}
            step={500}
            onChange={handleProfitRangeChange}
          />
          <div className="profit-range-values">
            <span>{profitRange[0]}</span>
            <span>-</span>
            <span>{profitRange[1]}</span>
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr className='prod-title'>
            <th>Termék</th>
            <th>Tárhely</th>
            <th>Szolgáltató</th>
            <th>Állapot</th>
            <th>Város</th>
            <th>Ár</th>
            <th>Profit</th>
            <th>Platform</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((product, index) => {
            const productKey = `${product._id}`;
            return (
              <React.Fragment key={index}>
                <tr ref={el => productRefs.current[productKey] = el} className={`${highlightedProduct === productKey ? 'highlight' : ''} ${product.highlightColor || ''}`} onClick={() => toggleDropdown(productKey)}>
                <td>{product.longest_model !== 'N/A' ? product.longest_model : product.product}</td>
                  <td>{product.storage}</td>
                  <td>{product.sim}</td>
                  <td>{product.condition}</td>
                  <td>{product.city}</td>
                  <td>{product.price}</td>
                  <td className={getProfitClass(product)}>
                    {calculateProfit(product)}
                  </td>
                  <td>{product.platform}</td>
                  <td>
                    <a href={product.link} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </td>
                </tr>
                {dropdownVisible === productKey && (
                  <tr className="prod-dropdown-content">
                    <td colSpan="9">
                      <div className="prod-dropdown">
                        <img src={editIcon} alt="Edit" className="edit-icon" onClick={() => toggleEditInput(productKey)} />
                        {editInputVisible === productKey && (
                          <>
                            <input
                              type="number"
                              placeholder="Ár megadása"
                              value={newProductPrice[productKey] || getSavedPrice(product) || ''}
                              onChange={(e) => handlePriceChange(productKey, e.target.value)}
                            />
                            <button className='prod-dropd-btn' onClick={() => handleAddProductToSheet(product)}>Mentés</button>
                          </>
                        )}
                        <div className="color-picker-container">
                          <div className="color-picker-header">
                            <h3>Kiemelés</h3>
                          </div>
                          <div className="color-picker-content">
                            <div className="color-box reset" onClick={() => handleColorChange(productKey, '')}></div>
                            <div className="color-box red" onClick={() => handleColorChange(productKey, 'red')}></div>
                            <div className="color-box yellow" onClick={() => handleColorChange(productKey, 'yellow')}></div>
                            <div className="color-box green" onClick={() => handleColorChange(productKey, 'green')}></div>
                          </div>
                        </div>
                        <img src={priceTracker} alt="PriceTracker" className="edit-icon" onClick={() => handleTrackProduct(product)} />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Products;
