import { useEffect, useState } from "react";
import React from 'react';
import { useLocation } from "react-router-dom";

const HideNotNeeded = ({ children }) => {
  const location = useLocation();
  const [showNavBar, setShowNavBar] = useState(true);

  useEffect(() => {
    if (location.pathname === '/login' || location.pathname === '*') {
      setShowNavBar(false);
    } else {
      setShowNavBar(true);
    }
  }, [location]);

  return (
    <div>{showNavBar && children}</div>
  );
}

export default HideNotNeeded;
