import { API_URL } from '../config';
import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import './login.css';

const Signin = () => {
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [signInData, setSignInData] = useState({ email: '', password: '' });
  const [signUpData, setSignUpData] = useState({ username: '', email: '', password: '', confirmPassword: '' });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { isAuthenticated, login, loading } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const toggleSignUpMode = () => {
    setIsSignUpMode(!isSignUpMode);
    setError('');
    setSuccess('');
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignInChange = (e) => {
    const { name, value } = e.target;
    setSignInData({ ...signInData, [name]: value });
  };

  const handleSignUpChange = (e) => {
    const { name, value } = e.target;
    setSignUpData({ ...signUpData, [name]: value });
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!validateEmail(signInData.email)) {
      setError('Kérjük, adjon meg egy érvényes e-mail címet.');
      return;
    }

    const success = await login(signInData.email, signInData.password);
    if (success) {
      navigate('/dashboard');
    } else {
      setError('Téves emailcím vagy jelszó. Kérjük, próbálja újra.');
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!validateEmail(signUpData.email)) {
      setError('Kérjük, adjon meg egy érvényes e-mail címet.');
      return;
    }

    if (signUpData.password !== signUpData.confirmPassword) {
      setError('A jelszavak nem egyeznek.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signUpData),
      });

      const data = await response.json();
      if (response.ok) {
        setSuccess('Fiók sikeresen létrehozva. Most már bejelentkezhet.');
        setIsSignUpMode(false);
      } else {
        setError(data.error);
      }
    } catch (err) {
      setError('A regisztráció sikertelen. Kérjük, próbálja meg újra.');
    }
  };

  return (
    <div className={`loginContainer ${isSignUpMode ? 'sign-up-mode' : ''}`}>
      <div className="signin-signup">
        <form className="sign-in-form" onSubmit={handleSignInSubmit}>
          <h2 className="loginTitle">Bejelentkezés</h2>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <div className="input-field">
            <i className="fas fa-user"></i>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={signInData.email}
              onChange={handleSignInChange}
              autoComplete="off"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              name="password"
              placeholder="Jelszó"
              value={signInData.password}
              onChange={handleSignInChange}
            />
          </div>
          <input type="submit" value="Login" className="loginBtn" />
          <p className="social-text">Elfelejtette jelszavát? Visszaállítás</p>
          <p className="account-text">
          Nincs még fiókja? <span id="sign-up-btn2" onClick={toggleSignUpMode}>Regisztrálok</span>
          </p>
        </form>
        <form className="sign-up-form" onSubmit={handleSignUpSubmit}>
          <h2 className="loginTitle">Regisztráció</h2>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}
          <div className="input-field">
            <i className="fas fa-user"></i>
            <input
              type="text"
              name="username"
              placeholder="Felhasználónév"
              value={signUpData.username}
              onChange={handleSignUpChange}
              autoComplete="off"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-envelope"></i>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={signUpData.email}
              onChange={handleSignUpChange}
              autoComplete="off"
            />
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              name="password"
              placeholder="Jelszó"
              value={signUpData.password}
              onChange={handleSignUpChange}
            />
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              name="confirmPassword"
              placeholder="Jelszó megerősítése"
              value={signUpData.confirmPassword}
              onChange={handleSignUpChange}
            />
          </div>
          <input type="submit" value="Sign up" className="loginBtn" />
          <p className="account-text">
          Már van fiókja? <span id="sign-in-btn2" onClick={toggleSignUpMode}>Bejelentkezés</span>
          </p>
        </form>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="loginContent">
            <h3>A ProfitX tagja?</h3>
            <p>Jelentkezzen be fiókjába, hogy hozzáférjen a leggyorsabb értesítésekhez, kihagyhatatlan ajánlatokhoz és exkluzív eszközökhöz. Maradjon a piaci lehetőségek élén!</p>
            <button className="loginBtn" id="sign-in-btn" onClick={toggleSignUpMode}>Bejelentkezés</button>
          </div>
          <img src="signin.svg" alt="" className="loginImage" />
        </div>
        <div className="panel right-panel">
          <div className="loginContent">
            <h3>Új a ProfitX-nél?</h3>
            <p>Csatlakozzon hogy a versenytársak előtt maradjon! Kapjon valós idejű értesítéseket és használja ki a hatékony eszközöket.</p>
            <button className="loginBtn" id="sign-up-btn" onClick={toggleSignUpMode}>Regisztáció</button>
          </div>
          <img src="signup.svg" alt="" className="loginImage" />
        </div>
      </div>
    </div>
  );
};

export default Signin;
