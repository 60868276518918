import { API_URL } from '../config';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './reports.css';

const Reports = () => {
  const [statistics, setStatistics] = useState(null);
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMarketData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/market-statistics`); // Adjust API endpoint
        setStatistics(response.data.statistics);
        setInsights(response.data.insights);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch market data');
        setLoading(false);
      }
    };

    fetchMarketData();
  }, []);

  if (loading) {
    return <div className="esemenyek-container">Loading...</div>;
  }

  if (error) {
    return <div className="esemenyek-container">{error}</div>;
  }

  return (
    <div className="esemenyek-container">
      <h1>Események</h1>
      <div className="statistics">
        <h2>Market Statistics</h2>
        {statistics ? (
          <ul>
            {statistics.map((stat, index) => (
              <li key={index}>{stat}</li>
            ))}
          </ul>
        ) : (
          <p>No statistics available</p>
        )}
      </div>
      <div className="insights">
        <h2>Market Insights</h2>
        {insights ? (
          <ul>
            {insights.map((insight, index) => (
              <li key={index}>{insight}</li>
            ))}
          </ul>
        ) : (
          <p>No insights available</p>
        )}
      </div>
    </div>
  );
};

export default Reports;
