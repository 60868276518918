import { API_URL } from '../config';
import React, { useState, useEffect, useCallback } from 'react';
import './analytics.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Analytics = () => {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      console.log('Fetching data');
      const response = await fetch(`${API_URL}/api/products`);
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!data || data.length === 0) return <div>No data available</div>;

  const totalProducts = data.length;
  const highestPricedProduct = data.reduce((max, product) => (product.price > max.price ? product : max), data[0]);
  const averagePrice = (data.reduce((sum, product) => sum + product.price, 0) / totalProducts).toFixed(2);

  const priceDistribution = {
    labels: data.map(product => product.product),
    datasets: [
      {
        label: 'Price',
        data: data.map(product => product.price),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };



  return (
    <div className="analytics-container">
      <div className="overview-cards">
        <div className="card">
          <h3>Total Products</h3>
          <p>{totalProducts}</p>
        </div>
        <div className="card">
          <h3>Highest Priced Product</h3>
          <p>{highestPricedProduct.product}</p>
          <p>Price: {highestPricedProduct.price}</p>
        </div>
        <div className="card">
          <h3>Average Price</h3>
          <p>{averagePrice}</p>
        </div>
      </div>
      <div className="charts">
        <h2>Price Distribution</h2>
        <Bar data={priceDistribution} />
      </div>
    </div>
  );
};

export default Analytics;
