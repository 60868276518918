import React, { useState } from 'react';
import './tracking.css';

const Tracking = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedProduct(prevSelected =>
      prevSelected.includes(value)
        ? prevSelected.filter(option => option !== value)
        : [...prevSelected, value]
    );
  };

  return (
    <div className="tracking-container">
      <div className="filters">
        <div className="filter-group">
          <label>Termék:</label>
          <div className="dropdown">
            <button className="dropdown-btn" onClick={toggleDropdown}>
              Keresés...<span className="material-symbols-outlined">keyboard_arrow_down</span>
            </button>
            <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
              <div className="category">
                <label className='dropdown-catogary-title'>
                  <input type="checkbox" value="iPhone" onChange={handleCheckboxChange} />
                  iPhone
                </label>
                <div>
                  <label>
                    <input type="checkbox" value="iPhone 6s" onChange={handleCheckboxChange} />
                    iPhone 6s
                  </label>
                </div>
                <div>
                  <label>
                    <input type="checkbox" value="iPhone 7" onChange={handleCheckboxChange} />
                    iPhone 7
                  </label>
                </div>
                <div>
                  <label>
                    <input type="checkbox" value="iPhone 1" onChange={handleCheckboxChange} />
                    iPhone 1
                  </label>
                </div>
                <div>
                  <label>
                    <input type="checkbox" value="iPhone 2" onChange={handleCheckboxChange} />
                    iPhone 2
                  </label>
                </div>
              </div>
              <div className="category">
                <label className='dropdown-catogary-title'>
                  <input type="checkbox" value="Samsung" onChange={handleCheckboxChange} />
                  Samsung
                </label>
                <div>
                  <label>
                    <input type="checkbox" value="Samsung 1" onChange={handleCheckboxChange} />
                    Samsung 1
                  </label>
                </div>
                <div>
                  <label>
                    <input type="checkbox" value="Samsung 2" onChange={handleCheckboxChange} />
                    Samsung 2
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracking;