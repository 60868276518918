import { API_URL } from '../config';
import React, { useState } from 'react';
import './settings.css';

const Settings = ({ userData, fetchUserData }) => {
  const [editField, setEditField] = useState('');
  const [nickname, setNickname] = useState(userData.username);
  const [status, setStatus] = useState(userData.status || '');
  const [email, setEmail] = useState(userData.email);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSave = async (field) => {
    let url = '';
    let body = {};

    switch (field) {
      case 'nickname':
        url = `${API_URL}/api/users/${userData._id}/nickname`;
        body = { username: nickname };
        break;
      case 'status':
        url = `${API_URL}/api/users/${userData._id}/status`;
        body = { status };
        break;
      case 'email':
        url = `${API_URL}/api/users/${userData._id}/email`;
        body = { email };
        break;
      default:
        return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body)
      });

      const data = await response.json();
      if (response.ok) {
        setSuccessMessage(`${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully`);
        setEditField('');
        // Fetch updated user data after successful save
        fetchUserData();
      } else {
        setErrorMessage(data.error || `Failed to update ${field}`);
      }
    } catch (error) {
      setErrorMessage(`Failed to update ${field}`);
    }
  };

  return (
    <div className="settings-container">
      <div className="section">
        <h2>Account</h2>
        <div className="account-info">
          <div className="info-row">
            <div className="info-title">Nickname</div>
            <div className="info-detail">
              {editField === 'nickname' ? (
                <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} className="nickname-input" />
              ) : (
                userData.username
              )}
            </div>
            <div className="info-action">
              {editField === 'nickname' ? (
                <>
                  <button onClick={() => handleSave('nickname')}>Save</button>
                  <button onClick={() => setEditField('')}>Cancel</button>
                </>
              ) : (
                <button onClick={() => setEditField('nickname')}>Edit</button>
              )}
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Status</div>
            <div className="info-detail">
              {editField === 'status' ? (
                <select value={status} onChange={(e) => setStatus(e.target.value)} className="nickname-input">
                  <option value="None">None</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Mrs.">Mrs.</option>
                </select>
              ) : (
                userData.status || 'None'
              )}
            </div>
            <div className="info-action">
              {editField === 'status' ? (
                <>
                  <button onClick={() => handleSave('status')}>Save</button>
                  <button onClick={() => setEditField('')}>Cancel</button>
                </>
              ) : (
                <button onClick={() => setEditField('status')}>Choose</button>
              )}
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Email</div>
            <div className="info-detail">
              {editField === 'email' ? (
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="nickname-input" />
              ) : (
                userData.email
              )}
            </div>
            <div className="info-action">
              {editField === 'email' ? (
                <>
                  <button onClick={() => handleSave('email')}>Save</button>
                  <button onClick={() => setEditField('')}>Cancel</button>
                </>
              ) : (
                <button onClick={() => setEditField('email')}>Change</button>
              )}
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Password</div>
            <div className="info-detail">********</div>
            <div className="info-action"><button>Reset</button></div>
          </div>
        </div>
        {successMessage && <p className="success">{successMessage}</p>}
        {errorMessage && <p className="error">{errorMessage}</p>}

        <h2>Advanced</h2>
        <div className="account-info">
          <div className="info-row">
            <div className="info-title">Notifications</div>
            <div className="info-detail">
                <button>On</button>
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Language</div>
            <div className="info-detail">
                <button>Choose</button>
            </div>
          </div>
        </div>

        <h2>Billing</h2>
        <div className="account-info">
          <div className="info-row">
            <div className="info-title">**** **** **** 9342</div>
            <div className="info-detail">
            <button>Choose</button>
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Reaccurring</div>
            <div className="info-detail">
                <button>On</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  );
};

export default Settings;