import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './topbar.css';

const TopBar = ({ userData }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [greeting, setGreeting] = useState('');
  const location = useLocation();

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const formattedDate = `${year}. ${month}. ${day}.`;
    setCurrentDate(formattedDate);

    // Set greeting based on the time of day
    const currentHour = today.getHours();
    if (currentHour < 12) {
      setGreeting('Jó Reggelt');
    } else if (currentHour < 18) {
      setGreeting('Üdvözlöm');
    } else {
      setGreeting('Szép Estét');
    }
  }, []);

  // Extract the active page name from the URL
  const activePage = location.pathname.split('/')[1] || 'home';

  return (
    <div className="top-bar">
      <div className="page-info">
        <h1>{activePage.charAt(0).toUpperCase() + activePage.slice(1)}</h1>
        <div className="date">{currentDate}</div>
      </div>
      <div className="user-info">
        <div className="profile">
          <div className="info">
            <p>{greeting}, <b>{userData.status} {userData.username}</b></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
