import React from 'react';
import './NotFound.css'; // Ensure the CSS file exists and is correctly referenced

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <a href="/login">Go to Login</a>
    </div>
  );
};

export default NotFound;
