import React from 'react';
import successIcon from '../assetes/img/1check.png'; // Make sure to update the path to your icon
import errorIcon from '../assetes/img/1cancel.png'; // Make sure to update the path to your icon
import './PopupAlert.css';

const PopupAlert = ({ message, type, visible }) => {
  return (
    visible && (
      <div className={`popup-alert ${type}`}>
        <img src={type === 'success' ? successIcon : errorIcon} alt={type} className="popup-alert-icon" />
        <span>{message}</span>
      </div>
    )
  );
};

export default PopupAlert;