import { API_URL } from '../config';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ModelsEdit = () => {
  const { marketId } = useParams();
  const navigate = useNavigate();
  const [market, setMarket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchMarket = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/markets/${marketId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMarket(response.data);
      } catch (error) {
        console.error('Error fetching market:', error);
        setErrorMessage('Error fetching market data.');
      }
    };

    fetchMarket();
  }, [marketId]);

  const handleDeleteMarket = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/api/markets/${marketId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/models'); // Redirect to models page after deletion
    } catch (error) {
      console.error('Error deleting market:', error);
      setErrorMessage('Error deleting market.');
    }
  };

  if (!market) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{market.name}</h1>
      <p>Keyword: {market.keyword}</p>
      <p>Location: {market.location}</p>
      <p>Markets: {market.markets.join(', ')}</p>
      <button onClick={handleDeleteMarket}>Delete Market</button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default ModelsEdit;
