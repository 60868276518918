import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './sidebar.css';
import profxLogo from '../assetes/img/profx.png'

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the token from localStorage
    localStorage.removeItem('token');
    // Redirect to the login page
    navigate('/login');
  };

  const menuItem = [
    { path: "/dashboard", name: "Vezérlőpult", icon: "grid_view" },
    { path: "/products", name: "Termékek", icon: "inventory" },
    { path: "/models", name: "Piacok", icon: "receipt_long" },
    { path: "/settings", name: "Beállítások", icon: "settings" }
  ];

  return (
    <div className="sidebar-container">
      <aside>
        <div className="top">
          <div className="logo">
          <img src={profxLogo} alt="Logo" />
          </div>
          <div className="close" id="close-btn">
            <span className="material-symbols-outlined">close</span>
          </div>
        </div>

        <div className="sidebar">
          {menuItem.map((item, index) => (
            <NavLink to={item.path} key={index} className="link" activeclassname="active">
              <span className="material-symbols-outlined">{item.icon}</span>
              <div className="link_text">{item.name}</div>
            </NavLink>
          ))}
          <div className="link" onClick={handleLogout}>
            <span className="material-symbols-outlined">logout</span>
            <div className="link_text">Kijelentkezés</div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;