// Dashboard.jsx
import React, { useEffect, useState } from 'react';
import useProducts from '../hooks/useProducts';
import useActiveSheet from '../hooks/useActiveSheet';
import './dashboard.css';
import './home.css';
import rectBg from '../assetes/img/right_bg.png';

const Dashboard = () => {
  const { products } = useProducts();
  const { activeSheet } = useActiveSheet();
  const [totalProfit, setTotalProfit] = useState(0);

  const latestProducts = products.slice(0, 5);

  useEffect(() => {
    if (activeSheet && products.length > 0) {
      const calculateTotalProfit = () => {
        let totalProfit = 0;
        products.forEach(product => {
          const matchingProduct = activeSheet.products.find(sheetProduct =>
            sheetProduct.series === product.longest_model &&
            sheetProduct.memory === product.storage &&
            sheetProduct.condition === product.condition &&
            sheetProduct.sim === product.sim
          );
          if (matchingProduct) {
            const sellingPrice = parseFloat(matchingProduct.price);
            const buyingPrice = parseFloat(product.price);
            const profit = sellingPrice - buyingPrice;
            if (profit > 0) {
              totalProfit += profit;
            }
          }
        });
        setTotalProfit(totalProfit);
      };

      calculateTotalProfit();
    }
  }, [activeSheet, products]);

  const calculateProfit = (product) => {
    if (!activeSheet) return null;
    const matchingProduct = activeSheet.products.find(sheetProduct =>
      sheetProduct.series === product.longest_model &&
      sheetProduct.memory === product.storage &&
      sheetProduct.condition === product.condition &&
      sheetProduct.sim === product.sim
    );
    if (matchingProduct) {
      const sellingPrice = parseFloat(matchingProduct.price);
      const buyingPrice = parseFloat(product.price);
      const profit = sellingPrice - buyingPrice;
      return profit >= 0 ? `+${profit.toFixed(2)}` : profit.toFixed(2);
    }
    return null;
  };

  const getProfitClass = (product) => {
    const profit = calculateProfit(product);
    return profit && parseFloat(profit) > 0 ? 'positive-profit' : '';
  };

  return (
    <div className="dashboard-container">
      <main className="main-content">
        <div className="insights">
          <div className="sales">
            <span className="material-symbols-outlined">sell</span>
            <div className="middle">
              <div className="left">
                <h3>Potenciális Profit</h3>
                <h1>{totalProfit.toFixed(2)} Ft</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>81%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Utolsó 24 Órában</small>
          </div>
          <div className="expenses">
            <span className="material-symbols-outlined">mintmark</span>
            <div className="middle">
              <div className="left">
                <h3>Talált Termékek</h3>
                <h1>{products.length}</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>62%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Utolsó 24 Órában</small>
          </div>
          <div className="income">
            <span className="material-symbols-outlined">paid</span>
            <div className="middle">
              <div className="left">
                <h3>Valami Adat</h3>
                <h1>$25,024</h1>
              </div>
              <div className="progress">
                <svg>
                  <circle cx="38" cy="38" r="36"></circle>
                </svg>
                <div className="number">
                  <p>81%</p>
                </div>
              </div>
            </div>
            <small className="text-muted">Utolsó 24 Órában</small>
          </div>
        </div>
        <div className="recent-products">
          <h2>Legújabb Termékek</h2>
          <table>
            <thead>
              <tr>
                <th>Termék</th>
                <th>Tárhely</th>
                <th>Szolgáltató</th>
                <th>Állapot</th>
                <th>Ár</th>
                <th>Profit</th>
                <th>Dátum</th>
                <th>Város</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {latestProducts.map((product, index) => (
                <tr key={index}>
                  <td>{product.longest_model}</td>
                  <td>{product.storage}</td>
                  <td>{product.sim}</td>
                  <td>{product.condition}</td>
                  <td>{product.price}</td>
                  <td className={getProfitClass(product)}>
                    {calculateProfit(product)}
                  </td>
                  <td>{product.date}</td>
                  <td>{product.city}</td>
                  <td>
                    <a href={product.link} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <a href="/products">Show All</a>
        </div>
      </main>
      <aside className="right">
        <div className="recent-updates">
          <h2>Friss Események</h2>
          <div className="updates">
            <div className="update">
              <div className="profile-photo">
                <img src="" alt="Product Img" />
              </div>
              <div className="message">
                <p><b>iPhone 12 - 80.000 Ft</b> new potential offer.</p>
                <small className="text-muted">2 Minutes Ago</small>
              </div>
            </div>
            <div className="update">
              <div className="profile-photo">
                <img src="" alt="Product Img" />
              </div>
              <div className="message">
                <p><b>iPhone 12 - 80.000 Ft</b> new potential offer.</p>
                <small className="text-muted">2 Minutes Ago</small>
              </div>
            </div>
            <div className="update">
              <div className="profile-photo">
                <img src="" alt="Product Img" />
              </div>
              <div className="message">
                <p><b>iPhone 12 - 80.000 Ft</b> new potential offer.</p>
                <small className="text-muted">2 Minutes Ago</small>
              </div>
            </div>
          </div>
        </div>
        <div className="sales-analytics">
        <div className="rect-rightBg">
            <img src={rectBg} alt="ProfitXBG" />
          </div>
          <h2>Piacok</h2>
          <div className="item online">
            <div className="icon">
              <span className="material-symbols-outlined">phone_iphone</span>
            </div>
            <div className="right-info">
              <div className="info">
                <h3>Okos Telefon</h3>
                <small className="success">Active</small>
              </div>
            </div>
          </div>
          <div className="item add-product">
            <div>
              <span className="material-symbols-outlined">add</span>
              <a href="/models">
              <h3 href="/models">Új Piac</h3>
              </a>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Dashboard;